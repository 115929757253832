$phone: 640px;
$tablet: 821px;
$desktop: 1024px;

.IngredientInfoComponent {
  max-width: 900px;
  > div.ingredient-name {
    align-items: center;
    display: flex;
    > h1 {
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      letter-spacing: 0.10rem;
      margin-right: 1rem;
      @media screen and (max-width: $phone) {
        font-size: 16px;
        margin-right: 0.5rem;
      }
    }
    > p {
      cursor: pointer;
    }
  }
  > div.ingredient-info {
    p {
      font-family: 'Poppins', sans-serif;
      line-height: 1.4rem;
    }
    a {
      color: #545454;
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      text-decoration: none;
    }
  }
}
