$phone: 640px;
$tablet: 821px;
$desktop: 1024px;

.IngredientEducationComponent {
  > div.ingredients-chart {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-top: 3rem;
    width: 80%;
    @media screen and (max-width: $phone) {
      margin-left: 3%;
      width: 94%;
    }
    > img {
      margin: 1rem 0;
      max-height: 650px;
      max-width: 900px;
      width: 100%;
    }
  }
  > div.ingredients-collection {
    margin-left: 20%;
    margin-top: 5rem;
    width: 60%;
    @media screen and (max-width: $phone) {
      margin-left: 5%;
      width: 90%;
    }
  }
}
