$phone: 640px;
$tablet: 821px;

.FooterComponent {
  align-items: center;
  background-color: #a68b80;
  color: #f4f4f4;
  display: flex;
  font-family: poppins, sans-serif;
  font-size: 10px;
  height: 35px;
  justify-content: center;
  width: 100%;
  > p {
    margin-right: 2rem;
    @media screen and (max-width: $phone) {
      margin-right: 1rem;
    }
  }
  > a {
    cursor: pointer;
  }
}
