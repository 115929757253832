.ArticlePageComponent {
  align-items: center;
  display: flex;
  flex-direction: column;
  > div.header {
    align-items: center;
    background-image: url('../../Images/background.png');
    display: flex;
    flex-direction: column;
    height: 350px;
    justify-content: center;
    width: 100%;
    > p {
      font-family: 'Poppins', sans-serif;
      font-size: 13px;
      font-weight: bold;
      margin: 0;
      text-transform: uppercase;
    }
    > h1 {
      font-family: "Albra Display Regularf", serif;
      font-size: 50px;
      font-weight: 400;
      line-height: 50px;
      margin-top: 20px;
      max-width: 550px;
      text-align: center;
    }
  }
  > div.image-container {
    background-color: #ffffff;
    box-shadow: 1px 1px 4px 0 #545454;
    display: flex;
    justify-content: center;
    margin-bottom: 55px;
    margin-top: -55px;
    padding: 2rem 2rem;
    width: 650px;
    > img {
      max-width: 100%;
      min-width: 100%;
    }
  }
  > div.content {
    font-size: 19px;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
    line-height: 32px;
    margin-bottom: 50px;
    max-width: 700px;
    width: 50%;
  }
  > div.sources {
    max-width: 700px;
    width: 50%;
    > h3 {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-weight: 300;
    }
    a {
      color: #545454;
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      text-decoration: none;
    }
  }
}
