.ArticleOverviewComponent {
  background-color: #ffffff;
  box-shadow: 1px 1px 4px 0 #545454;
  color: #000000;
  display: flex;
  height: fit-content;
  flex-direction: column;
  padding: 1.5rem;
  width: 25%;
  &:hover {
    cursor: pointer;
    h2 {
      color: #a68b80;
    }
  }
  > a {
    color: #000000;
    text-decoration: none;
    img {
      width: 100%;
    }
    p {
      font-family: 'Poppins', sans-serif;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
    }
    h2 {
      font-family: "Albra Display Regularf", serif;
      font-size: 28px;
      font-weight: 400;
      line-height: 33px;
      margin: 0;
    }
  }
}
